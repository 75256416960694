var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getRegistryErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('registries:specificInformation')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 mb-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'registries:specificInformation'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"data-cy-registry":"Name","error-messages":_vm.getRegistryErrors.stepOne.fields
                                                .name,"label":`${_vm.$t(
                                            'registries:registryName'
                                        )}*`},model:{value:(_vm.getRegistry.name),callback:function ($$v) {_vm.$set(_vm.getRegistry, "name", $$v)},expression:"getRegistry.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"data-cy-registry":"Index","error-messages":_vm.getRegistryErrors.stepOne.fields
                                                .index,"label":`${_vm.$t(
                                            'registries:index'
                                        )}*`},model:{value:(_vm.getRegistry.index),callback:function ($$v) {_vm.$set(_vm.getRegistry, "index", $$v)},expression:"getRegistry.index"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-registry":"Url","error-messages":_vm.getRegistryErrors.stepOne.fields
                                                .url,"label":`${_vm.$t('registries:url')}*`},model:{value:(_vm.getRegistry.url),callback:function ($$v) {_vm.$set(_vm.getRegistry, "url", $$v)},expression:"getRegistry.url"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-registry":"Icon","error-messages":_vm.getRegistryErrors.stepOne.fields
                                                .icon,"label":`${_vm.$t('registries:icon')}*`},model:{value:(_vm.getRegistry.icon),callback:function ($$v) {_vm.$set(_vm.getRegistry, "icon", $$v)},expression:"getRegistry.icon"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.select.status,"error-messages":_vm.getRegistryErrors.stepOne.fields
                                                .visible,"item-text":"title","item-value":"value","label":`${_vm.$t(
                                            'registries:active'
                                        )}*`},model:{value:(_vm.getRegistry.visible),callback:function ($$v) {_vm.$set(_vm.getRegistry, "visible", $$v)},expression:"getRegistry.visible"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'registries:registrySettings'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-select',{ref:"select",attrs:{"label":`${_vm.$t(
                                            'registries:statuses'
                                        )}*`,"item-text":"name","items":_vm.getPossibleStatuses,"item-value":"_id","error-messages":_vm.getRegistryErrors.stepOne.fields
                                                .defaultStatus,"data-cy-registry":"DefaultStatus","return-object":""},on:{"input":v => v || _vm.clearMiniModal()},scopedSlots:_vm._u([{key:"selection",fn:function({
                                                item,
                                            }){return [_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(`${_vm.$t( 'registries:default' )}`))])]}},{key:"item",fn:function(data){return [_c('div',{staticClass:"d-flex align-center justify-space-between full-width"},[_c('span',[_vm._v(_vm._s(data.item.name))]),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editPossibleStatus(
                                                                data.item
                                                            )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removePossibleStatus(
                                                                data.item
                                                            )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)])]}}]),model:{value:(_vm.getRegistry.defaultStatus),callback:function ($$v) {_vm.$set(_vm.getRegistry, "defaultStatus", $$v)},expression:"getRegistry.defaultStatus"}},[_c('template',{slot:"append-outer"},[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"max-width":"500","min-width":"500","origin":"center"},on:{"input":v =>
                                                        v ||
                                                        _vm.clearMiniModal()},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on: menu,
                                                    }){return [_c('v-tooltip',{attrs:{"top":"","data-cy-registry":"AddStatusTooltips"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                on: tooltip,
                                                            }){return [_c('v-icon',_vm._g({attrs:{"data-cy-registry":"AddStatusButton"},on:{"click":function($event){_vm.type =
                                                                        'create'}}},{
                                                                    ...menu,
                                                                    ...tooltip,
                                                                }),[_vm._v("mdi-plus")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'registries:addStatus' )))])])]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',[_c('v-container',{attrs:{"data-cy":"addStatus"}},[_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"md":"12"}},[_c('span',{staticClass:"headline font-weight-light"},[_vm._v(_vm._s(_vm.type == 'create' ? _vm.$t( 'additionStatus' ) : _vm.$t( 'editStatus' )))])]),_c('v-col',{staticClass:"py-0 my-0",attrs:{"md":"8"}},[_c('v-text-field',{attrs:{"data-cy-status":"Name","error-messages":_vm.newStatusErr.name,"label":`${_vm.$t(
                                                                        'name'
                                                                    )}*`},model:{value:(
                                                                        _vm.newStatus.name
                                                                    ),callback:function ($$v) {_vm.$set(_vm.newStatus, "name", $$v)},expression:"\n                                                                        newStatus.name\n                                                                    "}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"md":"4"}},[_c('v-select',{attrs:{"data-cy-status":"State","items":_vm.select.state,"item-text":"name","item-value":"value","label":`${_vm.$t(
                                                                        'state'
                                                                    )}`},model:{value:(
                                                                        _vm.newStatus.state
                                                                    ),callback:function ($$v) {_vm.$set(_vm.newStatus, "state", $$v)},expression:"\n                                                                        newStatus.state\n                                                                    "}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('span',[_vm._v(_vm._s(_vm.$t( 'backgroundColor' )))]),_c('v-color-picker',{attrs:{"data-cy-status":"BackgroundColor","mode":_vm.mode},on:{"update:mode":function($event){_vm.mode=$event}},model:{value:(
                                                                        _vm.newStatus
                                                                            .color
                                                                            .background
                                                                    ),callback:function ($$v) {_vm.$set(_vm.newStatus
                                                                            .color
                                                                            , "background", $$v)},expression:"\n                                                                        newStatus\n                                                                            .color\n                                                                            .background\n                                                                    "}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('span',[_vm._v(_vm._s(_vm.$t( 'fontColor' )))]),_c('v-color-picker',{attrs:{"data-cy-statis":"FontColor","mode":_vm.mode},on:{"update:mode":function($event){_vm.mode=$event}},model:{value:(
                                                                        _vm.newStatus
                                                                            .color
                                                                            .font
                                                                    ),callback:function ($$v) {_vm.$set(_vm.newStatus
                                                                            .color
                                                                            , "font", $$v)},expression:"\n                                                                        newStatus\n                                                                            .color\n                                                                            .font\n                                                                    "}})],1),_c('v-col',{staticClass:"py-0 my-0 d-flex justify-end",attrs:{"md":"12"}},[(
                                                                        _vm.type ==
                                                                        'create'
                                                                    )?_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"data-cy-status":"Add"},on:{"click":function($event){return _vm.createStatus(
                                                                            _vm.newStatus
                                                                        )}}},[_vm._v(_vm._s(_vm.$t( 'add' )))]):_c('v-btn',{staticClass:"buttons buttons--add",on:{"click":function($event){return _vm.updateStatus(
                                                                            _vm.newStatus
                                                                        )}}},[_vm._v(_vm._s(_vm.$t( 'edit' )))])],1)],1)],1)],1)],1)],1)],2)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-select',{ref:"select",attrs:{"data-cy-registry":"addFolder","label":`${_vm.$t(
                                            'registries:folders'
                                        )}* +${
                                            _vm.getPossibleFolders
                                                ? _vm.getPossibleFolders.length
                                                : 0
                                        }`,"item-text":"name","items":_vm.getPossibleFolders,"error-messages":_vm.getRegistryErrors.stepOne.fields
                                                .availableFolders,"item-value":"_id","return-object":""},on:{"input":v => v || _vm.clearFolderModal()},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"d-flex align-center justify-space-between full-width"},[_c('span',[_vm._v(_vm._s(data.item.name))]),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editFolder(
                                                                data.item
                                                            )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeFolder(
                                                                data.item
                                                            )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)])]}}])},[_c('template',{slot:"append-outer"},[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":true,"max-width":"300","min-width":"300","origin":"center"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on: menu,
                                                    }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                on: tooltip,
                                                            }){return [_c('v-icon',_vm._g({attrs:{"data-cy":"addFolderButton"},on:{"click":function($event){_vm.type =
                                                                        'create'}}},{
                                                                    ...menu,
                                                                    ...tooltip,
                                                                }),[_vm._v("mdi-plus")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'registries:addFolder' )))])])]}}]),model:{value:(_vm.showFolderMenu),callback:function ($$v) {_vm.showFolderMenu=$$v},expression:"showFolderMenu"}},[_c('v-card',{attrs:{"data-cy":"folderMenu"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"md":"12"}},[_c('span',{staticClass:"headline font-weight-light"},[_vm._v(_vm._s(_vm.type == 'create' ? _vm.$t( 'additionFolder' ) : _vm.$t( 'registries:editFolder' )))])]),_c('v-col',{staticClass:"py-0 my-0",attrs:{"md":"12"}},[_c('v-text-field',{attrs:{"data-cy-registry":"folderName","label":_vm.$t(
                                                                            'name'
                                                                        ),"error-messages":_vm.newFolderErr.name},model:{value:(
                                                                        _vm.newFolder.name
                                                                    ),callback:function ($$v) {_vm.$set(_vm.newFolder, "name", $$v)},expression:"\n                                                                        newFolder.name\n                                                                    "}})],1),_c('v-col',{staticClass:"py-0 my-0 d-flex justify-end",attrs:{"md":"12"}},[(
                                                                        _vm.type ==
                                                                        'create'
                                                                    )?_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"data-cy":"add"},on:{"click":function($event){return _vm.createFolder(
                                                                            _vm.newFolder
                                                                        )}}},[_vm._v(_vm._s(_vm.$t( 'add' )))]):_c('v-btn',{staticClass:"buttons buttons--add",on:{"click":function($event){return _vm.updateFolderName(
                                                                            _vm.newFolder
                                                                        )}}},[_vm._v(_vm._s(_vm.$t( 'edit' )))])],1)],1)],1)],1)],1)],1)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":`${_vm.$t(
                                            'registries:defaultFilesStatus'
                                        )}*`,"items":_vm.statuses,"item-text":"name","item-value":"value"},model:{value:(_vm.getRegistry.statusFiles),callback:function ($$v) {_vm.$set(_vm.getRegistry, "statusFiles", $$v)},expression:"getRegistry.statusFiles"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":`${_vm.$t(
                                            'registries:changeArchive'
                                        )}`},model:{value:(_vm.getRegistry.changeArchive),callback:function ($$v) {_vm.$set(_vm.getRegistry, "changeArchive", $$v)},expression:"getRegistry.changeArchive"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"hidden-data",attrs:{"items":_vm.getRegistryPossibleMenagers,"multiple":"","data-cy-registry":"PossibleMenagers","error-messages":_vm.getRegistryErrors.stepOne.fields
                                                .availableMenagers,"label":`${_vm.$t(
                                            'possibleMenagers'
                                        )}*`,"item-value":"_id","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(selection){return [_c('span',[_vm._v(_vm._s(selection.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(selection.item.lastname))]),(
                                                    _vm.getRegistry
                                                        .availableMenagers
                                                        .length > 1
                                                )?_c('span',{staticClass:"mr-1"},[_vm._v(",")]):_vm._e()]}},{key:"item",fn:function(item){return [_c('span',[_vm._v(_vm._s(item.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.item.lastname))])]}}]),model:{value:(
                                            _vm.getRegistry.availableMenagers
                                        ),callback:function ($$v) {_vm.$set(_vm.getRegistry, "availableMenagers", $$v)},expression:"\n                                            getRegistry.availableMenagers\n                                        "}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"cursor-pointer",attrs:{"items":_vm.getRegistry.availableMenagers,"error-messages":_vm.getRegistryErrors.stepOne.fields
                                                .currentMenager,"item-value":"_id","data-cy-registry":"DefaultMenager","label":`${_vm.$t(
                                            'registries:defaultMenager'
                                        )}*`},scopedSlots:_vm._u([{key:"selection",fn:function(selection){return [_vm._v(_vm._s(selection.item.name + ' ' + selection.item.lastname))]}},{key:"item",fn:function(item){return [_vm._v(_vm._s(item.item.name + ' ' + item.item.lastname))]}}]),model:{value:(_vm.getRegistry.currentMenager),callback:function ($$v) {_vm.$set(_vm.getRegistry, "currentMenager", $$v)},expression:"getRegistry.currentMenager"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }