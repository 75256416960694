<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getRegistryErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('registries:specificInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="py-0 mb-0">
                                        <Separator
                                            :text="
                                                $t(
                                                    'registries:specificInformation'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="5" class="py-0">
                                        <v-text-field
                                            data-cy-registry="Name"
                                            v-model="getRegistry.name"
                                            :error-messages="
                                                getRegistryErrors.stepOne.fields
                                                    .name
                                            "
                                            :label="`${$t(
                                                'registries:registryName'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="5" class="py-0">
                                        <v-text-field
                                            v-model="getRegistry.index"
                                            data-cy-registry="Index"
                                            :error-messages="
                                                getRegistryErrors.stepOne.fields
                                                    .index
                                            "
                                            :label="`${$t(
                                                'registries:index'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            v-model="getRegistry.url"
                                            data-cy-registry="Url"
                                            :error-messages="
                                                getRegistryErrors.stepOne.fields
                                                    .url
                                            "
                                            :label="`${$t('registries:url')}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            v-model="getRegistry.icon"
                                            data-cy-registry="Icon"
                                            :error-messages="
                                                getRegistryErrors.stepOne.fields
                                                    .icon
                                            "
                                            :label="`${$t('registries:icon')}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select
                                            :items="select.status"
                                            v-model="getRegistry.visible"
                                            :error-messages="
                                                getRegistryErrors.stepOne.fields
                                                    .visible
                                            "
                                            item-text="title"
                                            item-value="value"
                                            :label="`${$t(
                                                'registries:active'
                                            )}*`"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t(
                                                    'registries:registrySettings'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6" class="py-0">
                                        <v-select
                                            :label="`${$t(
                                                'registries:statuses'
                                            )}*`"
                                            item-text="name"
                                            :items="getPossibleStatuses"
                                            item-value="_id"
                                            :error-messages="
                                                getRegistryErrors.stepOne.fields
                                                    .defaultStatus
                                            "
                                            v-model="getRegistry.defaultStatus"
                                            data-cy-registry="DefaultStatus"
                                            return-object
                                            ref="select"
                                            @input="v => v || clearMiniModal()"
                                        >
                                            <template
                                                v-slot:selection="{
                                                    item,
                                                }"
                                            >
                                                <span>{{ item.name }}</span
                                                ><span class="ml-1">{{
                                                    `${$t(
                                                        'registries:default'
                                                    )}`
                                                }}</span>
                                            </template>
                                            <template slot="append-outer">
                                                <v-menu
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :close-on-click="false"
                                                    @input="
                                                        v =>
                                                            v ||
                                                            clearMiniModal()
                                                    "
                                                    max-width="500"
                                                    min-width="500"
                                                    origin="center"
                                                    v-model="showMenu"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on: menu,
                                                        }"
                                                    >
                                                        <v-tooltip
                                                            top
                                                            data-cy-registry="AddStatusTooltips"
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on: tooltip,
                                                                }"
                                                            >
                                                                <v-icon
                                                                    data-cy-registry="AddStatusButton"
                                                                    v-on="{
                                                                        ...menu,
                                                                        ...tooltip,
                                                                    }"
                                                                    @click="
                                                                        type =
                                                                            'create'
                                                                    "
                                                                    >mdi-plus</v-icon
                                                                >
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'registries:addStatus'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                    <v-card>
                                                        <v-container
                                                            data-cy="addStatus"
                                                        >
                                                            <v-row>
                                                                <v-col
                                                                    md="12"
                                                                    class="my-0 py-0"
                                                                >
                                                                    <span
                                                                        class="headline font-weight-light"
                                                                        >{{
                                                                            type ==
                                                                            'create'
                                                                                ? $t(
                                                                                      'additionStatus'
                                                                                  )
                                                                                : $t(
                                                                                      'editStatus'
                                                                                  )
                                                                        }}</span
                                                                    >
                                                                </v-col>
                                                                <v-col
                                                                    md="8"
                                                                    class="py-0 my-0"
                                                                >
                                                                    <v-text-field
                                                                        data-cy-status="Name"
                                                                        :error-messages="
                                                                            newStatusErr.name
                                                                        "
                                                                        :label="`${$t(
                                                                            'name'
                                                                        )}*`"
                                                                        v-model="
                                                                            newStatus.name
                                                                        "
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    md="4"
                                                                    class="py-0 my-0"
                                                                >
                                                                    <!-- <v-checkbox
                                                                        
                                                                        v-model="
                                                                            newStatus.resolved
                                                                        "
                                                                        :label="
                                                                            $t(
                                                                                'closed'
                                                                            )
                                                                        "
                                                                    ></v-checkbox> -->
                                                                    <v-select
                                                                        data-cy-status="State"
                                                                        :items="
                                                                            select.state
                                                                        "
                                                                        v-model="
                                                                            newStatus.state
                                                                        "
                                                                        item-text="name"
                                                                        item-value="value"
                                                                        :label="`${$t(
                                                                            'state'
                                                                        )}`"
                                                                    ></v-select>
                                                                </v-col>
                                                                <v-col md="6">
                                                                    <span>{{
                                                                        $t(
                                                                            'backgroundColor'
                                                                        )
                                                                    }}</span>
                                                                    <v-color-picker
                                                                        data-cy-status="BackgroundColor"
                                                                        :mode.sync="
                                                                            mode
                                                                        "
                                                                        v-model="
                                                                            newStatus
                                                                                .color
                                                                                .background
                                                                        "
                                                                    ></v-color-picker>
                                                                </v-col>
                                                                <v-col md="6">
                                                                    <span>{{
                                                                        $t(
                                                                            'fontColor'
                                                                        )
                                                                    }}</span>
                                                                    <v-color-picker
                                                                        data-cy-statis="FontColor"
                                                                        :mode.sync="
                                                                            mode
                                                                        "
                                                                        v-model="
                                                                            newStatus
                                                                                .color
                                                                                .font
                                                                        "
                                                                    ></v-color-picker>
                                                                </v-col>
                                                                <v-col
                                                                    md="12"
                                                                    class="py-0 my-0 d-flex justify-end"
                                                                >
                                                                    <v-btn
                                                                        data-cy-status="Add"
                                                                        v-if="
                                                                            type ==
                                                                            'create'
                                                                        "
                                                                        class="buttons buttons--add"
                                                                        @click="
                                                                            createStatus(
                                                                                newStatus
                                                                            )
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'add'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                    <v-btn
                                                                        v-else
                                                                        class="buttons buttons--add"
                                                                        @click="
                                                                            updateStatus(
                                                                                newStatus
                                                                            )
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'edit'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                                </v-menu>
                                            </template>
                                            <template v-slot:item="data">
                                                <div
                                                    class="d-flex align-center justify-space-between full-width"
                                                >
                                                    <span>{{
                                                        data.item.name
                                                    }}</span>
                                                    <div @click.stop>
                                                        <v-btn
                                                            icon
                                                            @click="
                                                                editPossibleStatus(
                                                                    data.item
                                                                )
                                                            "
                                                        >
                                                            <v-icon small
                                                                >mdi-pencil</v-icon
                                                            >
                                                        </v-btn>
                                                        <v-btn
                                                            icon
                                                            @click="
                                                                removePossibleStatus(
                                                                    data.item
                                                                )
                                                            "
                                                        >
                                                            <v-icon small
                                                                >mdi-delete</v-icon
                                                            >
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <v-select
                                            data-cy-registry="addFolder"
                                            :label="`${$t(
                                                'registries:folders'
                                            )}* +${
                                                getPossibleFolders
                                                    ? getPossibleFolders.length
                                                    : 0
                                            }`"
                                            item-text="name"
                                            :items="getPossibleFolders"
                                            :error-messages="
                                                getRegistryErrors.stepOne.fields
                                                    .availableFolders
                                            "
                                            item-value="_id"
                                            return-object
                                            ref="select"
                                            @input="
                                                v => v || clearFolderModal()
                                            "
                                        >
                                            <template slot="append-outer">
                                                <v-menu
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :close-on-click="true"
                                                    max-width="300"
                                                    min-width="300"
                                                    origin="center"
                                                    v-model="showFolderMenu"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on: menu,
                                                        }"
                                                    >
                                                        <v-tooltip top>
                                                            <template
                                                                v-slot:activator="{
                                                                    on: tooltip,
                                                                }"
                                                            >
                                                                <v-icon
                                                                    data-cy="addFolderButton"
                                                                    v-on="{
                                                                        ...menu,
                                                                        ...tooltip,
                                                                    }"
                                                                    @click="
                                                                        type =
                                                                            'create'
                                                                    "
                                                                    >mdi-plus</v-icon
                                                                >
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'registries:addFolder'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                    <v-card
                                                        data-cy="folderMenu"
                                                    >
                                                        <v-container>
                                                            <v-row>
                                                                <v-col
                                                                    md="12"
                                                                    class="my-0 py-0"
                                                                >
                                                                    <span
                                                                        class="headline font-weight-light"
                                                                        >{{
                                                                            type ==
                                                                            'create'
                                                                                ? $t(
                                                                                      'additionFolder'
                                                                                  )
                                                                                : $t(
                                                                                      'registries:editFolder'
                                                                                  )
                                                                        }}</span
                                                                    >
                                                                </v-col>
                                                                <v-col
                                                                    md="12"
                                                                    class="py-0 my-0"
                                                                >
                                                                    <v-text-field
                                                                        data-cy-registry="folderName"
                                                                        :label="
                                                                            $t(
                                                                                'name'
                                                                            )
                                                                        "
                                                                        :error-messages="
                                                                            newFolderErr.name
                                                                        "
                                                                        v-model="
                                                                            newFolder.name
                                                                        "
                                                                    ></v-text-field>
                                                                </v-col>

                                                                <v-col
                                                                    md="12"
                                                                    class="py-0 my-0 d-flex justify-end"
                                                                >
                                                                    <v-btn
                                                                        data-cy="add"
                                                                        v-if="
                                                                            type ==
                                                                            'create'
                                                                        "
                                                                        class="buttons buttons--add"
                                                                        @click="
                                                                            createFolder(
                                                                                newFolder
                                                                            )
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'add'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                    <v-btn
                                                                        v-else
                                                                        class="buttons buttons--add"
                                                                        @click="
                                                                            updateFolderName(
                                                                                newFolder
                                                                            )
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'edit'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                                </v-menu>
                                            </template>
                                            <template v-slot:item="data">
                                                <div
                                                    class="d-flex align-center justify-space-between full-width"
                                                >
                                                    <span>{{
                                                        data.item.name
                                                    }}</span>
                                                    <div @click.stop>
                                                        <v-btn
                                                            icon
                                                            @click="
                                                                editFolder(
                                                                    data.item
                                                                )
                                                            "
                                                        >
                                                            <v-icon small
                                                                >mdi-pencil</v-icon
                                                            >
                                                        </v-btn>
                                                        <v-btn
                                                            icon
                                                            @click="
                                                                removeFolder(
                                                                    data.item
                                                                )
                                                            "
                                                        >
                                                            <v-icon small
                                                                >mdi-delete</v-icon
                                                            >
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            :label="`${$t(
                                                'registries:defaultFilesStatus'
                                            )}*`"
                                            :items="statuses"
                                            v-model="getRegistry.statusFiles"
                                            item-text="name"
                                            item-value="value"
                                        >
                                        </v-select>
                                    </v-col>
                             
                                    <v-col cols="6">
                                        <v-checkbox
                                            :label="`${$t(
                                                'registries:changeArchive'
                                            )}`"                                           
                                            v-model="getRegistry.changeArchive"
                                            
                                           
                                        >
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6" class="py-0">
                                        <v-select
                                            class="hidden-data"
                                            :items="getRegistryPossibleMenagers"
                                            multiple
                                            data-cy-registry="PossibleMenagers"
                                            :error-messages="
                                                getRegistryErrors.stepOne.fields
                                                    .availableMenagers
                                            "
                                            v-model="
                                                getRegistry.availableMenagers
                                            "
                                            :label="`${$t(
                                                'possibleMenagers'
                                            )}*`"
                                            item-value="_id"
                                            return-object
                                        >
                                            <template
                                                v-slot:selection="selection"
                                            >
                                                <span>{{
                                                    selection.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    selection.item.lastname
                                                }}</span>
                                                <span
                                                    class="mr-1"
                                                    v-if="
                                                        getRegistry
                                                            .availableMenagers
                                                            .length > 1
                                                    "
                                                    >,</span
                                                >
                                            </template>
                                            <template v-slot:item="item">
                                                <span>{{
                                                    item.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    item.item.lastname
                                                }}</span>
                                            </template></v-select
                                        >
                                    </v-col>
                                    <v-col cols="12" md="6" class="py-0">
                                        <v-autocomplete
                                            :items="
                                                getRegistry.availableMenagers
                                            "
                                            v-model="getRegistry.currentMenager"
                                            :error-messages="
                                                getRegistryErrors.stepOne.fields
                                                    .currentMenager
                                            "
                                            item-value="_id"
                                            class="cursor-pointer"
                                            data-cy-registry="DefaultMenager"
                                            :label="`${$t(
                                                'registries:defaultMenager'
                                            )}*`"
                                            ><template
                                                v-slot:selection="selection"
                                                >{{
                                                    selection.item.name +
                                                    ' ' +
                                                    selection.item.lastname
                                                }}</template
                                            >
                                            <template v-slot:item="item">{{
                                                item.item.name +
                                                ' ' +
                                                item.item.lastname
                                            }}</template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import {
    GlobalStatus,
    GlobalFilesStatus,
} from '../../../../plugins/constsTypes'
export default {
    i18nOptions: { namespaces: ['registries'] },
    data() {
        return {
            e1: 1,
            mode: 'hexa',
            editable: true,
            showMenu: false,
            showFolderMenu: false,
            type: '',
            statuses: [
                {
                    name: this.$t('attachments:current'),
                    value: GlobalFilesStatus.current,
                },
                {
                    name: this.$t('attachments:archive'),
                    value: GlobalFilesStatus.archive,
                },
                {
                    name: this.$t('attachments:draft'),
                    value: GlobalFilesStatus.draft,
                },
                {
                    name: this.$t('attachments:confidential'),
                    value: GlobalFilesStatus.confidential,
                },
            ],
            select: {
                status: [
                    { title: this.$t('registries:yes'), value: true },
                    { title: this.$t('registries:no'), value: false },
                ],
                state: [
                    {
                        name: this.$t('registries:archive'),
                        value: GlobalStatus.archive,
                    },
                    {
                        name: this.$t('registries:current'),
                        value: GlobalStatus.active,
                    },
                    {
                        name: this.$t('registries:closed'),
                        value: GlobalStatus.closed,
                    },
                    {
                        name: this.$t('registries:draft'),
                        value: GlobalStatus.draft,
                    },
                ],
            },
            newFolder: {
                _id: '',
                name: '',
                isDeleted: false,
            },
            newFolderErr: { name: '' },
            newStatusErr: {
                name: '',
            },
            newStatus: {
                name: '',
                resolved: false,
                isDeleted: false,
                color: {
                    background: '#000000',
                    font: '#000000',
                },
            },
        }
    },
    methods: {
        ...mapMutations([
            'addNewStatus',
            'addNewFolder',
            'updatePossibleStatus',
            'updateFolder',
        ]),
        editPossibleStatus(obj) {
            let index = this.getPossibleStatuses.findIndex(
                item => item.name == obj.name
            )
            this.newStatus = obj
            this.type = 'edit'
            this.showMenu = true
        },
        createFolder(newFolder) {
            this.newFolder._id = this.getPossibleFolders.length + 1
            if (newFolder.name === '') {
                this.newFolderErr.name = `${this.$t('global:errorFields')}!`
            } else {
                this.addNewFolder(newFolder)
                this.clearFolderModal()
                
            }
        },
        editFolder(obj) {
            let index = this.getPossibleFolders.findIndex(
                item => item.name == obj.name
            )
            this.newFolder = obj
            this.type = 'edit'
            this.showFolderMenu = true
        },
        updateFolderName(newFolder) {
            if (newFolder.name === '') {
                this.newFolderErr.name = `${this.$t('global:errorFields')}!`
            } else {
                this.updateFolder(newFolder)
                this.clearFolderModal()
            }
        },
        updateStatus(newStatus) {
            if (newStatus.name === '') {
                this.newStatusErr.name = `${this.$t('global:errorFields')}!`
            } else {
                this.updatePossibleStatus(newStatus)
                this.clearMiniModal()
            }
        },
        removeFolder(obj) {
            let index = this.getPossibleFolders.findIndex(
                item => item.name == obj.name
            )
            this.getPossibleFolders[index].isDeleted = true
        },
        removePossibleStatus(obj) {
            let index = this.getPossibleStatuses.findIndex(
                item => item.name == obj.name
            )
            this.getPossibleStatuses[index].isDeleted = true
        },
        clearFolderModal() {
            this.showFolderMenu = false
            this.newFolderErr = { name: '' }
            this.newFolder = {
                name: '',
                isDeleted: false,
            }

        },
        clearMiniModal() {
            this.showMenu = false
            this.newStatusErr = {
                name: '',
            }
            this.newStatus = {
                name: '',
                resolved: false,
                isDeleted: false,
                color: { background: '#000000', font: '#000000' },
            }
        },
        createStatus(newStatus) {
            if (newStatus.name === '') {
                this.newStatusErr.name = `${this.$t('global:errorFields')}!`
            } else {
                this.addNewStatus(newStatus)
                this.clearMiniModal()
            }
        },
    },

    computed: {
        ...mapGetters([
            'getPossibleStatuses',
            'getPossibleFolders',
            'getRegistry',
            'getRegistryErrors',
            'getRegistryPossibleMenagers',
        ]),
    },
}
</script>
