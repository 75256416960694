<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <AdminHeader
                    :registryTitle="$t('global:registries')"
                    :registryUrl="'registries'"
                    @clearSearch="clearTable()"
                />
                <Table
                    :headers="headers"
                    :length="getRegistriesTable.pages"
                    :page="getRegistriesTable.page"
                    :defaultFields="getRegistriesSearchFields.searchFields"
                    @search="setSearch"
                    @change-page="setPage"
                    @sort="setSort"
                >
                    <Columns slot="columns" :items="getRegistriesTable.items" />
                </Table>
                <Modal
                    :title="$t('global:addition')"
                    :open="open"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        slot="buttons"
                        :action="'create'"
                    />
                </Modal>
                <div
                    class="d-flex justify-end buttons--action"
                    data-cy="addEntry"
                >
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                small
                                @click="openRegistryModal()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('registries:addRegistry') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Columns from './../../../components/Admin/Registries/Table/Columns'
import AEContent from './../../../components/Admin/Registries/Modal/Content'
import Buttons from './../../../components/Admin/Registries/Modal/Buttons'
import Rest from './../../../plugins/Rest'
import store from './../../../store/index'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['registries', 'global'] },
    components: {
        Buttons,
        Columns,
        AEContent,
    },
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('registries:registryName'),
                    value: 'name',
                    width: '56%',
                },
                {
                    text: this.$t('registries:index'),
                    value: 'index',
                    width: '10%',
                },
                {
                    text: this.$t('registries:presentMenager'),
                    value: 'currentMenager',
                    width: '12%',
                },
                {
                    text: this.$t('registries:introduced'),
                    value: 'introduced',
                    width: '10%',
                    sortable: false,
                },
                {
                    text: this.$t('registries:modificationDate'),
                    value: 'modificationDate',
                    width: '10%',
                    sortable: false,
                },
                {
                    value: 'action',
                    width: '2%',
                    text: '',
                    sortable: false,
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['getRegistriesTable', 'getRegistriesSearchFields']),
    },
    methods: {
        ...mapMutations([
            'setRegistriesTable',
            'clearRegistryModal',
            'clearRegistriesTable',
            'clearRegistryErrors',
        ]),
        ...mapActions(['fetchRegistriesTable']),
        openRegistryModal() {
            this.open = true
            this.fetchRegistriesTable({})
            clearRegistryModal()
        },
        closeModal() {
            this.clearRegistryErrors()
            this.open = false
        },
        setSearch(search) {
            this.setRegistriesTable({ search, page: 1 })
            this.fetchRegistriesTable({})
        },
        setPage(page) {
            this.setRegistriesTable({ page })
            this.fetchRegistriesTable({})
        },
        setSort(sort) {
            this.setRegistriesTable({ sort })
            this.fetchRegistriesTable({})
        },
        clearTable() {
            this.clearRegistriesTable()
            this.fetchRegistriesTable({})
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/registries/:id')
            store.commit('clearRegistriesTable')

        store.dispatch('fetchRegistriesTable', {}).then(() => next())
    },
}
</script>
