<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.name }}</td>
            <td class="truncate">
                <span>{{ item.index }}</span>
            </td>
            <td class="truncate">
                <span>{{ correctMenager(item.currentMenager) }}</span>
            </td>
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$moment(item.createdAt).format(`DD-MM-YYYY`)"
                    :secondLine="$moment(item.createdAt).format(`HH:mm`)"
                />
            </td>
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$moment(item.updatedAt).format(`DD-MM-YYYY`)"
                    :secondLine="$moment(item.updatedAt).format(`HH:mm`)"
                />
            </td>
            <td class="d-flex align-center justify-end" data-cy="details">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-btn small router :to="`/registries/${item._id}`" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
export default {
    props: ['items'],
    methods: {
        correctMenager(menager) {
            if (menager) return `${menager.name} ${menager.lastname}`
            return ''
        },
    },
}
</script>
