<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            class="buttons buttons--add"
            :disabled="disabled"
            data-cy="save"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['createRegistry', 'updateRegistry']),
        async save() {
            this.disabled = true
            let success = await this.createRegistry()
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            let success = await this.updateRegistry({
                id: this.$route.params.id,
            })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>
